import CartPage from '../views/CartPage.vue'
import HomePage from '../views/HomePage.vue'
import OrdersHistoryPage from '../views/OrdersHistoryPage.vue'
import Vue from 'vue'
import VueCookies from "vue-cookies";
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueCookies);
Vue.use(VueRouter);

const cookies = Vue.$cookies;

/**
 * List of available routes
 */
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'homePage',
    component: HomePage
  },
  {
    path: '/cart/',
    name: 'cartPage',
    component: CartPage
  },
  {
    path: '/storico-ordini/',
    name: 'historyPAge',
    component: OrdersHistoryPage
  },
]

/**
 * Create the vuejs router
 */
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Before each route check if the user is logged in
router.beforeEach((to, from, next) => {
  let page404:boolean=true;
  routes.forEach(el => {
    if(to.path==el.path) page404=false;
  });
  let auth = cookies.isKey("_identity");
  if (page404 || (!auth && (to.path == "/cart/" || to.path == "/storico-ordini/")))
    next({ path: "/" });
  else next();
});

export default router
