import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
//@ts-ignore
import store from './store'
import element from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import canvas from 'configurator-base-app'
import cookies from 'vue-cookies'
import VueI18n from 'vue-i18n'

import "./theme/theme-variables.less"
// Thema stile sito
import "./theme/index.less"
import "./theme/custom.less"

// configurazione iniziale di configurator-base-app
const configuratorSettings = {
  VUE_APP_REST_URL: `${process.env.VUE_APP_REST_URL}`,
  VUE_APP_CONFIG_IDENTIFIER: `${process.env.VUE_APP_CONFIG_IDENTIFIER}`,
  VUE_APP_COLORS_URL: `${process.env.VUE_APP_COLORS_URL}`,
  VUE_APP_MODEL_URL: `${process.env.VUE_APP_MODEL_URL}`,
  VUE_APP_CONFIG_DATABASE: `${process.env.VUE_APP_CONFIG_DATABASE}`,
  VUE_APP_FOR_APP: `${process.env.VUE_APP_FOR_APP}`,
  VUE_APP_IS_USA: process.env.VUE_APP_IS_USA
}

// utilizzo canvas di configuratore base
// @ts-ignore 
Vue.use(canvas, { store, settings: configuratorSettings })

// Utilizzo element-ui > impostando lingua inglese (di default compaiono messaggi di errore in cinese)
Vue.use(element);
locale.use(lang)

// Utilizzo vue cookies
Vue.use(cookies);
Vue.use(VueI18n);

Vue.config.productionTip = false

Vue.filter('upperCase', s => s.toUpperCase());

// Modelli lenti da vista
Vue.filter('decodeLensModel', (l: any) => {
  if (typeof l !== 'string') {
    return ''
  } else {
    if (l == 'MF1.50SF-UC') {
      return 'MONOFOCALE 1.50 SFERICA-UC'
    } else if (l == 'MF1.56SF-HMC') {
      return 'MONOFOCALE 1.56 SFERICA-HMC'
    } else if (l == 'MF1.56SF-HMC BLUCUT') {
      return 'MONOFOCALE 1.56 SFERICA-HMC BLUCUT'
    } else if (l == 'MF1.60AS-SHMC') {
      return 'MONOFOCALE 1.60 ASFERICA-SHMC'
    } else if (l == 'MF1.67AS-SHMC') {
      return 'MONOFOCALE 1.67 ASFERICA-SHMC'
    }
  }
  return l;
})

const i18n = new VueI18n({
  locale: 'IT',
  fallbackLocale: 'EN'
})

new Vue({
  i18n,
  router,
  //@ts-ignore
  store,
  render: h => h(App),
  created() {
    // @ts-ignore
    Vue.component('Canvas3D', this.$canvas3d);

    // @ts-ignore
    this.$rest.getCountryCode().then((response: { data: any }) => {
      i18n.locale = response.data
      // console.log("Lingua: " + i18n.locale);
    })

    // @ts-ignore
    this.$rest.getTranslations().then((response: { data: any }) => {
      const messages = response.data
      // console.log(messages);
      for (const key in messages) {
        i18n.setLocaleMessage(key, messages[key])
      }
    })

    // In caso sia necessario iniziare la navigazione utente con lenti da vista
    //this.$store.commit("configurator/setLensesType", 'V');
  }
}).$mount('#app')
