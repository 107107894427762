var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{attrs:{"id":"div-home-page"}},[_c('el-header',{staticClass:"border-bottom border-success",attrs:{"id":"page-header"}},[_c('HeaderCmp',{attrs:{"isHistoryPage":false}})],1),_c('el-main',{staticClass:"flex flex-col"},[_c('el-row',{staticClass:"canvas-container"},[_c('Canvas3D',{attrs:{"shouldLoadScene":true,"defaultFrontColor":_vm.defaultFrontColor,"defaultFrontModel":_vm.defaultFrontModel,"defaultTemplesColor":_vm.defaultTemplesColor}}),_c('configuration-action-menu',{attrs:{"defaultFrontColor":_vm.defaultFrontColor,"defaultFrontModel":_vm.defaultFrontModel,"defaultTemplesColor":_vm.defaultTemplesColor}}),(_vm.storeLocatorDialogVisible)?_c('StoreLocatorDialog',{attrs:{"open":_vm.storeLocatorDialogVisible},on:{"close":function($event){_vm.storeLocatorDialogVisible = false; _vm.activeMenu = null;}}}):_vm._e()],1),_c('div',{staticClass:"main-menu-ext-cont"},[_c('i',{staticClass:"el-icon-arrow-left pointer",on:{"click":function($event){return _vm.scrollMenu('right')}}}),_c('i',{staticClass:"el-icon-arrow-right pointer",on:{"click":function($event){return _vm.scrollMenu('left')}}}),_c('div',{staticClass:"main-menu-container",attrs:{"xs":24,"md":{ span: 20, offset: 2 },"lg":{ span: 18, offset: 3 }}},[_c('div',{staticClass:"main-menu-scroller",attrs:{"id":"menu-primary"}},[_c('div',{staticClass:"main-menu flex flex-row"},_vm._l((_vm.lstMainMenu),function(voce,x){return _c('el-col',{key:x,staticClass:"menu-row-col"},[_c('el-button',{staticClass:"menu-items",class:{ 'selected-menu': voce.selected },attrs:{"type":"text"},on:{"click":function($event){return _vm.setMenuItem(voce)}}},[_c('span',{class:{
                    'error-menubar':
                      _vm.checkErrorsFrontale.length > 0 &&
                      voce.name === 'message.frontale',
                  }},[_vm._v(_vm._s(_vm.$t(("" + (voce.name)))))]),(
                    voce.name === 'message.Confronta' &&
                    _vm.haveConfigurations
                  )?_c('div',{staticClass:"menu-items-number"},[_vm._v(" "+_vm._s(_vm.$store.state.configurator.configurations.length)+" ")]):_vm._e()])],1)}),1)])])]),_c('div',{staticClass:"main-component"},[_c('keep-alive',[_c(_vm.activeComponent,{tag:"component"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }