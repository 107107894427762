import Vue from 'vue'
import Vuex from 'vuex'
import { ConfiguratorState } from "configurator-base-app/dist/libModules/RootState"
import jslinq, { JsLinq } from 'jslinq'
import { Color } from 'configurator-base-app/dist/libModules/Colors'
import { Temple } from 'configurator-base-app/dist/libModules/Temple'
import { FrontModel } from 'configurator-base-app/dist/libModules/FrontModel'
import { Lenses } from 'configurator-base-app/dist/libModules/Lenses'

Vue.use(Vuex)

// Tipologia di oggetti sub-categoria colori plastiche
type categoriaColori={
  name: string;
  value: Array<string>;
}

// Tipolgia di colore con nome e min/max livello di colors.order
type colore={
  nome: string;
  label: string;
  max: number;
  min: number;
  style: {
    background: any;
  }
}

// definizione classe dello stato dell'applicazione
// TODO: Da valutare se mantenere MARMORIZZATI e GLITTER nelle categorie colori
class AppState {
  colorsCategories:Array<categoriaColori>=[
    {name:'BLACKS', value:['GLITTER', 'MARMORIZZATI', 'SOLIDI', 'TRASLUCIDI', 'NERO']},
    {name:'BLUES', value:['GLITTER', 'MARMORIZZATI', 'SOLIDI', 'TRASLUCIDI', 'BLUE', 'VIOLA',]},
    {name:'HAVANAS', value:['HAVANA']},
    {name:'BROWNS', value:['GLITTER', 'MARMORIZZATI', 'SOLIDI', 'TRASLUCIDI', 'MARRONE']},
    {name:'GREENS', value:['GLITTER', 'MARMORIZZATI', 'SOLIDI', 'TRASLUCIDI',  'GLITTER', 'VERDE']},
    {name:'REDS', value:['GLITTER', 'MARMORIZZATI', 'SOLIDI', 'TRASLUCIDI', 'ARANCIONE', 'GIALLO', 'ROSA', 'ROSSO']},
    {name:'WHITES', value:['GLITTER', 'MARMORIZZATI', 'SOLIDI', 'TRASLUCIDI', 'TRASPARENTI', 'BIANCO']},
    {name:'BICOLORS', value:['MULTILAYER']},
  ];
  colorsDeleted:Array<string>=['GLITTER', 'MARMORIZZATI', 'MULTISTRATO']; // TODO: Da valutare se da eliminare realmente
  colorsList:Array<colore>=[
    {
      nome: "ROSA",
      label: "PINK",
      max: 345,
      min: 300,
      style: {
        background: "#FF00FF",
      },
    },
    {
      nome: "VIOLA",
      label: "VIOLA",
      max: 299,
      min: 239,
      style: {
        background: "#8000FF",
      },
    },
    {
      nome: "BLUE",
      label: "BLUE",
      max: 238,
      min: 190,
      style: {
        background: "#0000FF",
      },
    },
    {
      nome: "VERDE",
      label: "GREEN",
      max: 189,
      min: 75,
      style: {
        background: "#00FF00",
      },
    },
    {
      nome: "GIALLO",
      label: "YELLOW",
      max: 74,
      min: 48,
      style: {
        background: "#FFFF00",
      },
    },
    {
      nome: "ARANCIONE",
      label: "ORANGE",
      max: 47,
      min: 14,
      style: {
        background: "#FF8000",
      },
    },
    {
      nome: "ROSSO",
      label: "RED",
      max: 13,
      min: 0,
      style: {
        background: "#FF0000",
      },
    },
    {
      nome: "MARRONE",
      label: "BROWN",
      max: 40,
      min: 14,
      style: {
        background: "#A35B03",
      },
    },
    {
      nome: "BIANCO/NERO",
      label: "BLACK/WHITE",
      max: -1,
      min: -2,
      style: {
        background: "linear-gradient(to bottom right, #FFFFFF 50%, #000000 50%)",
      },
    },
    {
      nome: "BIANCO",
      label: "WHITE",
      max: -1,
      min: -2,
      style: {
        background:"#FFFFFF",
      },
    },
    {
      nome: "NERO",
      label: "BLACK",
      max: -1,
      min: -2,
      style: {
        background:"#000000",
      },
    },
  ];
  configLentiV:boolean=false;
  configurator!:ConfiguratorState;
  openLoginDialog:boolean=false;
  orderColor:string="";
  orderColorFinishing:string="";
  orderLensesReference:string="";
  orderItemCode:string="";
  orderSize:string="";
  orderSun:boolean=false;

  erroreLentiDialog: boolean = false;
  lenteSx: Lenses | null = null;
  lenteDx: Lenses | null = null;
}

export default new Vuex.Store({
  state: new AppState(),
  getters: {
  },
  mutations: {
    openCloseConfigLentiV(state, c): void {
      state.configLentiV = c
    },

    openCloseLoginDialog(state, c):void{
      state.openLoginDialog=c;
    },

    setLenteSx(state, l) {
      state.lenteSx = l;
    },

    setLenteDx(state, l) {
      state.lenteDx = l;
    },
    
    resetLentiVista(state) {
      state.configurator.lenteVistaDx = null;
      state.configurator.lenteVistaSx = null;
    },

    openCloseDialogErroreLenti(state, c): void {
      if (!c || !state.configLentiV)
        state.erroreLentiDialog = c;
    },

    resetConfiguration(state, { forma, colore, coloreAste, sizeDescription }) {
      if (sizeDescription && state.configurator.frontali !== undefined) {

        let regularSize = state.configurator.frontali.itemsSizes.filter(size => {
          return size.size_description == sizeDescription &&
            size.U_THE_MODELLO == forma;
        })

        state.configurator.calibro = (regularSize[0].size_eye);
        state.configurator.naso = (regularSize[0].size_bridge);

      } else {
        state.configurator.calibro = '';
        state.configurator.naso = '';
      }

      state.configurator.asianFitting = false;
      if (state.configurator.frontali != undefined) {
        state.configurator.colore = state.configurator.frontali.colors.find((col: Color) => {
          return col.U_THE_COLORE == (colore ? colore : "PL0001");
        }) ?? null;
      } else {
        state.configurator.colore = null;
      }
      state.configurator.finitura = "S";
      state.configurator.misuraAste = '145';
      if (state.configurator.aste != undefined) {
        state.configurator.coloreAste = state.configurator.aste.allColors.find((col: Temple) => {
          return (
            col.U_THE_COLORE == (coloreAste ? coloreAste : "PL0001") &&
            col.U_THE_LUNGASTA == state.configurator.misuraAste
          );
        }) ?? null;
      }
      state.configurator.coloreMetalloAste = "";
      state.configurator.finituraAste = "S";
      // TODO: verificare come resettare le variabili di seguito
      if (state.configurator.frontali) {
        state.configurator.frontali.name = "";
        state.configurator.frontali!.nameFont = "Batang";
        state.configurator.frontali.nameColor = "";
      }
      if (state.configurator.aste) {

        state.configurator.aste.extSxName = '';
        state.configurator.aste.extSxNameColor = '';
        state.configurator.aste.extSxNameFont = 'Batang';
        state.configurator.aste.intSxName = '';
        state.configurator.aste.intSxNameColor = '';
        state.configurator.aste.intSxNameFont = 'Batang';
        state.configurator.aste.extDxName = '';
        state.configurator.aste.extDxNameColor = '';
        state.configurator.aste.extDxNameFont = 'Batang';
        state.configurator.aste.intDxName = '';
        state.configurator.aste.intDxNameColor = '';
        state.configurator.aste.intDxNameFont = 'Batang';
      }

      (state.configurator.note = "");

      if (forma && state.configurator.frontali != undefined) {

        if (forma.indexOf('S') >= 0 && state.configurator.glassType == 'V') {
          forma = forma.replace('S', '');
          const f: JsLinq<FrontModel> = jslinq(state.configurator.frontali.items);

          let fd = f.firstOrDefault!((front: FrontModel) => {
            return front.U_THE_MODELLO == forma;
          });
          // this.setFrontale(fd)
          state.configurator.frontale = fd;

        } else if (forma.indexOf('S') >= 0 && state.configurator.glassType == 'S') {
          const f: JsLinq<FrontModel> = jslinq(state.configurator.frontali.items);

          let fd = f.firstOrDefault!((front: FrontModel) => {
            return front.U_THE_MODELLO == forma;
          });
          state.configurator.frontale = fd;
        }

      } /* else {
        state.frontale = state.frontale;
      }*/
    }
  },
  actions: {
  },
  modules: {
  }
})
